<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-29 22:01:45
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-07 20:04:30
 * @Description  : 不带模板的运单创建页面
-->
<template>
  <div class="container">
    <div class="title font-heavy">{{ $t("new_shipment") }}</div>
    <!-- <div class="sub-title">
      {{ $t("alert_tip_key_for_create") }}
    </div> -->
    <div class="basic-settings font-heavy">
      <div class="title">
        <div>{{ $t("shipment_information") }}</div>
        <img src="@/assets/ic_close.png" alt="" @click="backToCreate" />
      </div>
      <el-divider></el-divider>

      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item :label="$t('shipment_name')" prop="name">
          <!-- 使用 v-html 来渲染包含 HTML 的标签 -->
          <template #label>
            <span
              v-html="`<span style='color:red'>*</span>${$t('shipment_name')}`"
            ></span>
          </template>
          <!-- 运单名称(获取模板时，name为多语言的key值，正常列表为输入的运单名称) -->
          <el-input
            v-model="form.shipmentInfo.name"
            class="input-style"
            :placeholder="$t('enter_name')"
          ></el-input>
        </el-form-item>
        <!-- 运单id -->
        <el-form-item :label="$t('shipment_id')">
          <el-input
            v-model="form.shipmentInfo.shipmentShowId"
            class="input-style"
            :placeholder="$t('enter_id_or_auto_generate')"
          ></el-input>
        </el-form-item>
        <!-- 货物名称 -->
        <el-form-item :label="$t('goods_name')">
          <el-input
            v-model="form.shipmentInfo.goodsName"
            class="input-style"
            :placeholder="$t('enter_goods_name')"
          ></el-input>
        </el-form-item>
        <!-- 发货人 -->
        <el-form-item :label="$t('shipper')">
          <el-input
            v-model="form.shipmentInfo.shipper"
            class="input-style"
            :placeholder="$t('enter_shipper')"
          ></el-input>
        </el-form-item>
        <!-- 收货人 -->
        <el-form-item :label="$t('consignee')">
          <el-input
            v-model="form.shipmentInfo.receiver"
            class="input-style"
            :placeholder="$t('enter_consignee')"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('note')">
          <el-input
            v-model="form.shipmentInfo.remark"
            class="input-style"
            type="textarea"
            :placeholder="$t('enter_note')"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>

        <div class="title">{{ $t("device_information") }}</div>
        <el-divider></el-divider>
        <div class="sub-title" style="color: #848484">
          {{ $t("select_loggers") }}
        </div>

        <div class="select-device">
          <div class="select-box" @click="selectDevice">
            <span> {{ selectedDevices }}</span>
            <img src="@/assets/ic_blue_arrow.png" alt="" srcset="" />
          </div>
        </div>

        <div class="sub-title">{{ $t("upload_interval") }}</div>
        <div class="sub-title" style="color: #848484">
          {{ $t("upload_interval_tip") }}
        </div>

        <div class="tab-box">
          <el-radio-group
            v-model="form.shipmentInfo.uploadInterval"
            size="small"
            @change="handleIntervalChange"
          >
            <el-radio-button
              :label="$t(item.value)"
              v-for="(item, index) in intervalList"
              :key="index"
              :style="{
                marginRight: index < intervalList.length - 1 ? '10px' : '0',
              }"
              class="tab-item"
              :class="{
                'is-active': form.shipmentInfo.uploadInterval === item.key,
                'is-disabled': item.key < minUploadInterval,
              }"
              :disabled="item.key < minUploadInterval"
            >
              {{ $t(item.value) }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <el-divider></el-divider>
        <div class="sub-title">{{ $t("alert_rules") }}</div>
        <div class="sub-tip">
          {{ $t("set_alert_rules_for_this_shipment") }}
        </div>
        <div class="selected-template-box">
          <div class="sub-title">{{ $t("selected_template") }}</div>
          <div class="sub-title temp">
            {{ $t(this.templateName) }}(
            {{ Number(this.min) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
            ~
            {{ Number(this.max) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }})
          </div>
          <el-button type="primary" class="change" @click="changeTemplate"
            >{{ $t("change") }}
          </el-button>
        </div>

        <el-button type="primary" class="add-rule" @click="addRule"
          >+{{ $t("add_new_alert_rule") }}
        </el-button>

        <el-form-item
          v-for="(rule, index) in form.detailList"
          :key="rule.key"
          :prop="'detailList.' + index + '.value'"
          label-width="0px"
        >
          <div class="rule-box">
            <div class="selects-container">
              <el-select
                v-model="rule.type"
                class="select-item custom-placeholder"
                @change="onTypeChange(rule)"
                :disabled="isEditDisabled(index)"
                placeholder="Select rule type"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmType"
                  :key="item.value"
                ></el-option>
              </el-select>
              <!-- 第二个 <el-select> 根据 isFirstSelectChosen 和 isNew 的值来决定是否禁用 -->
              <el-select
                v-model="rule.source"
                class="select-item custom-placeholder"
                @change="onSourceChange(rule)"
                :disabled="
                  !isFirstSelectChosen[rule.key] || isEditDisabled(index)
                "
                placeholder="Select data type"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in alarmSensor"
                  :key="index"
                ></el-option>
              </el-select>
              <!-- <el-select
                v-model="rule.conditions"
                class="select-item"
                placeholder="Select condition"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmConditions"
                  :key="item.value"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="rule.value"
                class="input-item"
              ></el-input> -->
              <el-input
                class="input-item custom-placeholder"
                placeholder="less than"
                disabled
              >
              </el-input>

              <el-input v-model.number="rule.min" class="input-item"></el-input>
              OR
              <el-input
                class="input-item custom-placeholder"
                placeholder="more than"
                disabled
              >
              </el-input>

              <el-input v-model.number="rule.max" class="input-item"></el-input>
              <el-input
                class="input-item"
                v-model="rule.unit"
                disabled
                v-if="!showTemperatureUnit(rule)"
              >
                {{ setSelectedTempType }}
              </el-input>
              <div v-if="showDurationSelector(rule)">
                For more than
                <el-input
                  v-model.number="rule.duration"
                  class="input-item"
                ></el-input>
                <el-input
                  placeholder="Minutes"
                  class="input-item custom-placeholder"
                  disabled
                  style="margin-left: 8px"
                ></el-input>
              </div>
            </div>
            <img
              @click.prevent="removeRule(rule)"
              src="../../assets/ic_cross.png"
              alt=""
            />
          </div>
        </el-form-item>

        <!-- <div class="sub-tip">
          {{ $t("delay_tip") }}
        </div> -->
        <div class="title" style="margin-top: 40px">
          {{ $t("advanced_settings") }}
        </div>
        <el-divider></el-divider>

        <div class="mode-box">
          <div class="sub-title" style="margin-right: 20px">
            {{ $t("mode") }}:
          </div>
          <div class="tab-box">
            <el-radio-group
              v-model="form.shipmentInfo.transModel"
              size="small"
              @change="handleModeChange"
            >
              <el-radio-button
                :label="item.code"
                v-for="(item, index) in modeList"
                :key="index"
                :style="{
                  marginRight: index < modeList.length - 1 ? '10px' : '0',
                }"
                class="tab-item"
                :class="{ 'is-active': isActiveMode === item.code }"
                >{{ $t(`${item.code.toLowerCase()}`) }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="sub-title">{{ $t("shipment_start_time") }}</div>
        <div class="sub-title" style="color: #848484">
          {{ $t("shipment_start_time_tip") }}
        </div>
        <el-date-picker
          v-model="form.shipmentInfo.displayStartTime"
          type="datetime"
          @change="handleStartTimeChange"
          :placeholder="$t('shipment_start_time')"
        >
        </el-date-picker>
        <div class="sub-title">{{ $t("shipment_end_time") }}</div>

        <div class="sub-title" style="color: #848484">
          {{ $t("shipment_end_time_tip") }}
        </div>
        <div class="sub-title" style="color: #848484">
          {{ $t("shipment_end_time_tip_2") }}
        </div>
        <el-date-picker
          v-model="form.shipmentInfo.displayEndTime"
          type="datetime"
          @change="handleEndTimeChange"
          :placeholder="$t('shipment_end_time')"
        >
        </el-date-picker>

        <div class="sub-title">{{ $t("alert_reception_time") }}</div>

        <el-radio-group v-model="alldayRadio" @change="onChangeTime">
          <div class="radio">
            <el-radio label="allday" style="min-width: 100px">{{
              $t("all_day")
            }}</el-radio>

            <div class="custom-time">
              <el-radio label="custom" style="min-width: 100px"
                >{{ $t("custom") }}(8:00 to 21:00)</el-radio
              >
            </div>

            <div v-if="!isAllDay" style="margin-left: 20px">
              <el-time-select
                v-model="form.shipmentInfo.startMin"
                :start="8"
                :end="20"
                :step="1"
                :picker-options="{
                  start: '08:00',
                  step: '01:00',
                  end: '21:00',
                }"
                placeholder="Start Time"
                style="width: 180px; margin-right: 10px"
              ></el-time-select>
              <span>to</span>
              <el-time-select
                v-model="form.shipmentInfo.endMin"
                :start="8"
                :end="20"
                :step="1"
                :picker-options="{
                  start: '08:00',
                  step: '01:00',
                  end: '21:00',
                }"
                placeholder="End Time"
                style="width: 180px; margin-left: 10px"
              ></el-time-select>
            </div>
          </div>
        </el-radio-group>

        <div class="sub-title">{{ $t("alert_notification_channels") }}</div>

        <div class="checkbox">
          <el-checkbox @change="onChangeInd">{{
            $t("app_notification")
          }}</el-checkbox>
          <el-checkbox @change="onChangeEmailInd">{{
            $t("Email")
          }}</el-checkbox>
        </div>
        <el-button type="primary" class="create-btn" @click="submitForm">
          {{ $t("create") }}</el-button
        >
      </el-form>
    </div>
    <AddShipmentToNewAlertDialog
      ref="deviceRef"
      @ok="getSelectedDeviceIds"
    ></AddShipmentToNewAlertDialog>
  </div>
</template>

<script>
import {
  selectApi,
  addShipmentApi,
  getShipmentAlarmConfigApi,
  getDeviceMinUploadIntervalApi,
} from "@/api/api.js";
import AddShipmentToNewAlertDialog from "./modal/AddShipmentToNewAlertDialog.vue";
import moment from "moment";
export default {
  name: "CreateShipmentWithoutTemplate",
  data() {
    return {
      minUploadInterval: 0,
      form: {
        deviceIdList: [], //设备id列表
        detailList: [], //配置详情
        //配置主信息
        shipmentInfo: {
          name: "",
          shipmentShowId: "",
          goodsName: "",
          shipper: "",
          receiver: "",
          remark: "",
          uploadInterval: "",
          transModel: "",
          startTime: null,
          displayStartTime: "",
          displayEndTime: "",
          endTime: null,
          startMin: "",
          endMin: "",
          appInd: "",
          emailInd: "",
          // id: 0,
          // userId: "",
          // shipmentId: "string",
          // plate: "",
          // status: "0",
          // templateInd: "",
          // alarmInd: "",
          // alarmNum: "",
          // latestDate: 0,
          // templateId: "",
          // templateName: "",
        },
      },
      alarmType: [],
      alarmSensor: [],
      alarmConditions: [],
      dialogFormVisible: false,
      // formRules: {
      //   name: [
      //     {
      //       required: true,
      //       message: this.$t("please_enter_shipment_name"),
      //       // trigger: "blur",
      //     },
      //   ],
      // },
      emailInputVisible: false,
      isAllDay: true,
      alldayRadio: "allday",
      templateId: "",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      selectedDevices: "",
      fileList: [],
      // 图片尺寸比例要求
      // aspectRatio: 23 / 183, // 设置为1:1，可以根据需要调整
      base64Image: "",
      selectedId: "0", // 初始时没有选中的图片
      previewImageUrl: null, // 预览图片的URL
      isPreviewVisible: false, // 控制预览图是否可见
      imageUrl: "",
      intervalList: [
        { key: 1, value: "1Minute" },
        { key: 5, value: "5Minute" },
        { key: 10, value: "10Minute" },
        { key: 15, value: "15Minute" },
        { key: 30, value: "30Minute" },
        { key: 45, value: "45Minute" },
        { key: 60, value: "60Minute" },
        { key: 90, value: "90Minute" },
        { key: 120, value: "120Minute" },
        { key: 600, value: "6Hour" },
        { key: 720, value: "12Hour" },
        { key: 1440, value: "24Hour" },
        { key: 2880, value: "48Hour" }, // 修改了这里的key值，避免重复
      ],
      modeList: [
        // { key: "1", value: "land" },
        // { key: "2", value: "sea" },
        // { key: "3", value: "air" },
        // { key: "4", value: "railway" },
        // { key: "5", value: "warehouse" },
        // { key: "6", value: "port" },
        // { key: "7", value: "others" },
      ],
      isFirstSelectChosen: {}, // 用于存储每个规则的第一个选择框是否已选择
      isActive: 1,
      isActiveMode: "1",
      min: "",
      max: "",
      templateName: "",
    };
  },
  methods: {
    isEditDisabled(index) {
      return this.form.detailList[index].isNew === undefined;
    },
    selectDevice() {
      this.$refs.deviceRef.showDialog();
    },

    removeRule(item) {
      const index = this.form.detailList.findIndex(
        (rule) => rule.key === item.key
      );
      if (index !== -1) {
        this.form.detailList.splice(index, 1);
      }
    },
    addRule() {
      this.form.detailList.push({
        // shipmentId: this.templateId,
        type: "",
        source: "",
        // conditions: "",
        // value: "",
        min: "",
        max: "",
        unit: "",
        key: Date.now(),
        duration: "",
        isNew: true, // 设置为新数据
      });
    },
    getShipmentAlarmConfig() {
      getShipmentAlarmConfigApi({ shipmentId: this.templateId }).then((r) => {
        console.log("r: ", r);

        this.form.detailList = r.data.map((item) => {
          console.log("item: ", JSON.stringify(item));
          // 处理单位字段
          item.unit = item.unit.toLowerCase() === "c" ? "°C" : "°F";
          // 将 source 和 type 转换为字符串
          item.source = String(item.source);
          item.type = String(item.type);
          return item;
        });
      });
    },
    getAlarmType() {
      selectApi({ type: "AlarmType" }).then((r) => {
        this.alarmType = r.data;
      });
    },
    getTransModel() {
      selectApi({ type: "TransModel" }).then((r) => {
        this.modeList = r.data;
      });
    },
    getAlarmSensor(code) {
      selectApi({ type: "AlarmSensor", pCode: code }).then((r) => {
        this.alarmSensor = r.data;
      });
    },
    getAlarmConditions() {
      selectApi({ type: "AlarmConditions" }).then((r) => {
        this.alarmConditions = r.data;
      });
    },

    getDeviceMinUploadInterval(arr) {
      let params = { shipmentId: this.templateId, deviceIdList: arr };
      getDeviceMinUploadIntervalApi(params).then((r) => {
        console.log("getDeviceMinUploadIntervalApi===", r);
        this.minUploadInterval = r.data;
        // 确保默认选中的值符合最小上传间隔要求
        if (this.form.shipmentInfo.uploadInterval < this.minUploadInterval) {
          this.form.shipmentInfo.uploadInterval =
            this.filteredIntervalList()[0].key; // 选择第一个符合条件的值
        }
      });
    },
    filteredIntervalList() {
      return this.intervalList.filter(
        (item) => item.key >= this.minUploadInterval
      );
    },
    getSelectedDeviceIds(temp) {
      this.getDeviceMinUploadInterval(temp);
      this.form.deviceIdList = temp;

      console.log("this.form.deviceIdList: ", this.form.deviceIdList);
      this.selectedDevices = this.form.deviceIdList.join(",");
    },
    toggleEmailInput(val) {
      console.log("val: ", val);
      this.emailInputVisible = val == true ? true : false;
    },
    onChangeInd(val) {
      console.log("onChangeInd--val: ", val);
      //Whether to push app alarm 0: Do not push 1: Push
      this.form.shipmentInfo.appInd = val == true ? "1" : "0";
      if (this.isAllDay) {
        (this.form.shipmentInfo.startMin = "00:00"),
          (this.form.shipmentInfo.endMin = "24:00");
      }
    },
    onChangeEmailInd(val) {
      console.log("onChangeEmailInd--val: ", val);
      //是否进行app推送告警 0：不推送 1：推送
      this.form.shipmentInfo.emailInd = val == true ? "1" : "0";
      if (this.isAllDay) {
        (this.form.shipmentInfo.startMin = "00:00"),
          (this.form.shipmentInfo.endMin = "24:00");
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.form.shipmentInfo.name) {
            this.$message.error(this.$t("please_enter_shipment_name"));
            return;
          }

          // if (this.form.deviceIdList.length <= 0) {
          //   this.$message.error(this.$t("at_least_select_one_device"));
          //   return;
          // }

          if (this.form.detailList.length <= 0) {
            this.$message.error(this.$t("at_least_add_one_rule"));
            return;
          }
          console.log(
            "this.form.shipmentInfo.startMin: ",
            this.form.shipmentInfo.startMin
          );

          // 验证时间范围是否有效
          if (
            this.form.shipmentInfo.startMin &&
            this.form.shipmentInfo.endMin
          ) {
            const start = this.form.shipmentInfo.startMin;
            const end = this.form.shipmentInfo.endMin;

            if (start >= end) {
              this.$message.error(
                this.$t("end_time_cannot_be_greater_than_start_time")
              );

              return;
            }
          }

          // if (this.emailInputVisible && !this.form.config.email) {
          //   this.$message.error(this.$t("email_cannot_be_empty"));
          //   return;
          // }
          console.log("this.form.detailList: ", this.form.detailList);

          this.form.detailList = this.form.detailList.map((item) => {
            if (item.unit === "°C") {
              item.unit = "c";
            } else if (item.unit === "°F") {
              item.unit = "f";
            }
            return item;
          });
          let params = {
            ...this.form,
          };
          addShipmentApi(params).then((r) => {
            console.log("=====", r);
            this.$message.success(this.$t("create_successful"));
            this.dialogFormVisible = false;
            this.goPages("/home/shipment");
          });
        } else {
          // console.error("Error submitting the form!");
          return false;
        }
      });
    },
    onChangeTime(val) {
      console.log("val: ", val);
      this.isAllDay = val == "allday" ? true : false;
    },
    backToCreate() {
      this.$router.go(-1);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },

    getFile(file) {
      // 清除之前的Base64图像
      this.clearUpload();
      this.getBase64(file.raw).then((res) => {
        console.log(res);
        this.base64Image = res;
      });
    },
    clearUpload() {
      // 清除已有的Base64图像
      this.base64Image = "";
      // 重置<el-upload>组件的状态
      this.$refs.upload.clearFiles();
    },
    onTypeChange(rule) {
      console.log("onTypeChange---rule: ", JSON.stringify(rule));
      // 当选择的类型改变时触发
      // this.showDatePicker(rule);
      // 当第一个选择框发生变化时，检查是否选择了有效选项
      this.isFirstSelectChosen[rule.key] =
        rule.type !== null && rule.type !== undefined;

      const alarmTypeItem = this.alarmType.find(
        (item) => item.value === rule.type
      );
      console.log("alarmTypeItem: ", JSON.stringify(alarmTypeItem));
      this.getAlarmSensor(alarmTypeItem.code);
    },
    showDatePicker(rule) {
      console.log("showDatePicker---rule: ", rule);
      // 根据 rule.type 的值判断是否显示日期选择器
      return rule.type === "2";
    },
    onSourceChange(rule) {
      console.log("onSourceChange---rule: ", rule);
      // 当选择的传感器改变时触发
      // this.showTempUnitSelector(rule);
      // rule.unit = this.showTempUnitSelector(rule)
      //   ? this.setSelectedTempType
      //   : "";
      rule.unit = this.setSelectedTempType;
    },
    // showTempUnitSelector(rule) {
    //   console.log("showTempUnitSelector---rule: ", rule);
    //   // 根据 rule.source 的值判断是否显示温度单位选择器
    //   return ["1", "4"].includes(rule.source);
    // },
    onSelectionChange(event) {
      const selectedValue = event.target.value;
      console.log("选中的图片ID:", selectedValue);
      this.imageUrl = selectedValue;
      // 这里可以添加更多的逻辑处理
    },
    showImagePreview(url) {
      this.previewImageUrl = url;
      this.isPreviewVisible = true;
    },
    closePreview() {
      this.isPreviewVisible = false;
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        // 监听 Esc 键
        this.closePreview();
      }
    },
    handleClickOutside(event) {
      console.log("event: ", event);
      const modal = event.currentTarget;
      console.log("modal: ", modal);
      if (modal.contains(event.target)) {
        this.closePreview();
      }
    },
    showDurationSelector(rule) {
      console.log("rule: ", JSON.stringify(rule));
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2"].includes(rule.type);
    },
    showTemperatureUnit(rule) {
      console.log("showTemperatureUnit---rule: ", JSON.stringify(rule));
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2", "8", "16", "32", "64"].includes(rule.source);
    },
    calculateWidth(length) {
      return 100 / length;
    },
    // clickTab(key) {
    //   this.isActive = key; // 设置当前激活的键值
    // },
    // clickTabMode(key) {
    //   this.isActiveMode = key; // 设置当前激活的键值
    // },
    handleIntervalChange(value) {
      console.log("value: ", value);

      // console.log("Selected option:", this.intervalList[value].key);

      // 找到对应的 item 并获取其 key
      const selectedItem = this.intervalList.find(
        (item) => item.value === value
      );
      console.log("selectedItem: ", JSON.stringify(selectedItem));

      if (selectedItem) {
        this.form.shipmentInfo.uploadInterval = selectedItem.key;
      }
      console.log(
        " this.form.shipmentInfo.uploadInterval: ",
        this.form.shipmentInfo.uploadInterval
      );
    },
    handleModeChange(value) {
      console.log("value: ", value);
      this.isActiveMode = value;

      // 找到对应的 item 并获取其 code
      const selectedItem = this.modeList.find((item) => item.code === value);
      console.log("selectedItem: ", JSON.stringify(selectedItem));
      if (selectedItem) {
        this.form.shipmentInfo.transModel = selectedItem.value;
      }
      console.log(
        "this.form.shipmentInfo.transModel: ",
        this.form.shipmentInfo.transModel
      );
    },
    // convertToTimestamp(dateString) {
    //   // 使用 moment 解析日期字符串
    //   const date = moment(dateString, "YYYY-MM-DD HH:mm");
    //   // 检查解析是否成功
    //   if (!date.isValid()) {
    //     console.error("Invalid date string:", dateString);
    //     return null;
    //   }
    //   // 获取时间戳（以毫秒为单位）
    //   const timestamp = date.valueOf();
    //   // 如果需要秒级时间戳，可以除以1000
    //   // const timestampInSeconds = Math.floor(timestamp / 1000);
    //   return timestamp; // 或者返回 timestampInSeconds 如果你需要的是秒级时间戳
    // },
    handleStartTimeChange(date) {
      console.log("Selected date: ", date);
      // 确保 date 是一个有效的日期字符串
      const utcTimestamp = moment.utc(date).valueOf();
      console.log("utcTimestamp: ", utcTimestamp);
      if (date) {
        this.form.shipmentInfo.startTime = utcTimestamp / 1000;
      } else {
        console.error("Invalid date format: ", date);
        this.form.shipmentInfo.startTime = null;
      }

      console.log(
        "this.form.shipmentInfo.startTime: ",
        this.form.shipmentInfo.startTime
      );
    },
    handleEndTimeChange(date) {
      console.log("Selected date: ", date);
      // 确保 date 是一个有效的日期字符串
      const utcTimestamp = moment.utc(date).valueOf();
      console.log("utcTimestamp: ", utcTimestamp);
      if (date) {
        this.form.shipmentInfo.endTime = utcTimestamp / 1000;
      } else {
        console.error("Invalid date format: ", date);
        this.form.shipmentInfo.endTime = null;
      }

      console.log(
        "this.form.shipmentInfo.endTime: ",
        this.form.shipmentInfo.endTime
      );
    },
    changeTemplate() {
      this.goPages("/createShipment");
    },
  },
  props: {},
  components: {
    AddShipmentToNewAlertDialog,
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.templateId = JSON.parse(this.$route.query.item).shipmentId;
    this.min = JSON.parse(this.$route.query.item).config.min;
    this.max = JSON.parse(this.$route.query.item).config.max;
    this.templateName = JSON.parse(this.$route.query.item).name;
    console.log("this.templateId: ", this.templateId);
    this.$i18n.locale = this.setSelectedLanguage;
    document.addEventListener("keydown", this.handleKeydown);
    this.getAlarmType();
    this.getTransModel();
    this.getAlarmSensor();
    // this.getAlarmConditions();
    this.getShipmentAlarmConfig();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 16px 47px 70px 30px;
  .title {
    font-size: 36px;
    font-weight: 500;
    color: #262626;
    line-height: 51px;
    height: 51px;
  }
  .sub-title {
    font-size: 14px;
    color: #262626;
    margin-top: 3px;
    line-height: 20px;
    height: 20px;
  }

  .basic-settings {
    background: white;
    margin-top: 14px;
    padding: 17px 19px 0 19px;
    border-radius: 8px;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 39px;
      height: 39px;
      font-size: 28px;
      color: #262626;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 97px;
      }
    }
    .input-style {
      width: 883px;
      margin-left: -150px;

      /deep/.el-input__inner {
        line-height: 54px;
        height: 54px;
      }
    }

    .sub-title {
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
      line-height: 26px;
      height: 26px;
      color: #262626;
    }

    .sub-tip {
      color: #848484;
      font-size: 16px;
      // margin-top: 5px;
    }
    .add-rule {
      width: 163px;
      background: #1972f4;
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 30px 0;
    }

    .radio {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .custom-time {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-input {
        width: 200px;
        margin-left: 20px;
      }
    }
    .rule-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(187, 187, 187, 1);
      padding: 5px 10px;
      // height: 49px;
      // line-height: 49px;
      margin-right: 50px;
      border-radius: 8px;
      // margin-left: -120px;
      img {
        width: 33px;
        height: 33px;
      }
      .selects-container {
        display: flex;
        gap: 8px;
        align-items: center;
        .select-item {
          width: 220px;
          height: 47px;
          line-height: 47px;
          /deep/.el-input__inner {
            background: #f4f9ff;
          }
        }
        .input-item {
          width: 100px;
          height: 47px;
          line-height: 47px;
          /deep/.el-input__inner {
            background: #f4f9ff;
          }
        }
      }
    }

    /* 自定义 el-radio 的样式 */
    .radio .el-radio {
      background-color: #f4f9ff;
      border: 1px solid #bbbbbb;
      height: 38px;
      line-height: 38px; /* 对齐文本垂直居中 */
      font-size: 18px;
      color: #848484; /* 文本颜色设为灰色 */
      border-radius: 8px;
      padding: 0 10px;
      // text-align: center;

      /* 调整内部元素的样式 */
      /deep/.el-radio__label {
        line-height: 38px; /* 对齐文本垂直居中 */
        font-size: 18px;
        color: #848484; /* 文本颜色设为灰色 */
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        border: 1px solid #101010;
        background-color: #f4f9ff;
      }

      /* 调整勾选状态下的样式 */
      /deep/.is-checked .el-radio__inner {
        background-color: #101010;
        border: 1px solid #101010;
        color: #848484;
      }

      /* 调整勾选状态下的图标 */
      .is-checked .el-radio__inner::after {
        width: 8px;
        height: 4px;
        transform: rotate(45deg);
        background-color: #fff;
        content: "";
        position: absolute;
        top: 6px;
        left: 5px;
      }

      .el-radio__input .is-checked .el-radio__label {
        color: #848484; /* 文本颜色设为灰色 */
      }
    }

    /* 调整内部元素的样式 */
    /deep/.el-checkbox__label {
      line-height: 38px; /* 对齐文本垂直居中 */
      font-size: 18px;
      color: #848484;
      font-size: 16px;
    }
    .selected-template-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 10px 0;
      .temp {
        color: #848484;
        margin: 0 20px;
      }
      .change {
        margin-left: 20px;
        background: #1972f4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .mode-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .select-device {
    // position: relative;
    display: flex;
    flex-direction: column;
    .select-box {
      width: 883px;
      line-height: 54px;
      height: 54px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;

      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: space-between; /* 水平居中 */
      span {
        padding: 0 10px;
        color: #848484;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 9px;
      }
    }
    //   .select-style {
    //     width: 883px;
    //     // z-index: -1;
    //     // position: absolute;
    //     /deep/.el-input__inner {
    //       line-height: 54px;
    //       height: 54px;
    //       background: white;
    //       margin-top: 5px;
    //       margin-bottom: 5px;
    //     }
    //   }

    //   /* 自定义下拉箭头 */
    //   /deep/.el-select .el-input .el-select__caret {
    //     visibility: hidden; /* 隐藏默认的下拉箭头 */
    //   }

    //   /* 自定义下拉箭头 */
    //   /deep/.el-input__suffix {
    //     display: flex;
    //     align-items: center; /* 垂直居中 */
    //     justify-content: center; /* 水平居中 */
    //     /* 如果需要，可以调整内边距以适应图标 */
    //     // padding: 0 8px;
    //   }

    //   /deep/.el-input__suffix-inner {
    //     background-image: url("../../assets/ic_blue_arrow.png");
    //     background-size: cover;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     font-family: ""; /* 清空字体家族 */
    //     content: ""; /* 清空内容 */
    //     width: 24px; /* 图片宽度 */
    //     height: 24px; /* 图片高度 */
    //   }
  }
  .create-btn {
    width: 135px;
    background: #1972f4;
    margin-top: 20px;
    margin-bottom: 122px;
  }
  .upload-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    .sub-title {
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
      line-height: 26px;
      height: 26px;
      color: #262626;
    }
  }
  .image-container {
    max-width: 100%;
    height: 230px;
    margin-right: 20px;
    .bg {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
    }
  }

  // .image-container img {
  //   max-width: 100%;
  //   height: 100%;
  //   position: relative;
  //   object-fit: cover;
  // }
  .h_line {
    background: #dcdfe6;
    height: 1px;
    width: 100%;
    margin: 20px 0;
  }
  .bg-img-box {
    display: flex;
    flex-direction: column;
    img {
      margin: 10px 10px 10px 0;
    }
  }

  .preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active 在 Vue 2.x 中 */ {
    opacity: 0;
  }
  /deep/.el-select .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::placeholder {
    color: #606266;
  }
}

.tab-box {
  display: flex;
  background: white;
  flex-direction: row;

  line-height: 40px;
  height: 40px;
  // margin-top: 40px;
  // border-right: 1px solid #bbbbbb;
  // padding-right: 10px;
  cursor: pointer;

  // width: 140px;
}
.tab-item {
  cursor: pointer;
  // padding: 5px 10px;
  // margin: 5px;
  line-height: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80px;
  text-align: center;
}

.is-active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
/deep/.el-form-item {
  height: 54px;
  line-height: 54px;
  margin-bottom: 15px;
  display: flex;
  // flex-direction: row;
}
/deep/.el-form-item__label {
  text-align: left;
  color: black;
  font-size: 18px;
}
/deep/.el-form-item__content {
  margin-left: 30px;
}
/* 覆盖默认样式 */
/deep/.el-radio-button__inner {
  transition: none !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  color: inherit !important;
  // font-size: inherit !important;
  // line-height: inherit !important;
  height: auto !important;
  width: auto !important;
  text-align: center !important;
}

.tab-item.is-active {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.tab-item.is-disabled {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-allowed;
}
</style>
